// ** Redux Imports
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"

// ** Axios Imports
import API from "api/vehicles"

export const getData = createAsyncThunk(
    "appVehicles/getData",
    async (params, {signal}) => {
        let response = await API.listVehicles(params)
        if (signal.aborted) return
        return {
            params,
            data: response.vehiclesList,
            totalRows: response.totalRows,
        }
    },
)

export const getVehicle = createAsyncThunk(
    "appVehicles/getVehicle",
    async (name, {dispatch, getState}) => {
        const loaded = getState().vehicles.data.filter(
            (e) => e.name === name,
        )[0]
        if (loaded) await dispatch(appVehiclesSlice.actions.setSelected(loaded))
        return await API.getVehicle(name)
    },
)

export const modifyVehicle = createAsyncThunk(
    "appVehicles/modifyVehicle",
    async (vehicle, {dispatch}) => {
        const updated = await API.updateVehicle(vehicle)
        vehicle = {
            ...vehicle,
            ...updated,
        }
        await dispatch(appVehiclesSlice.actions.setSelected(vehicle))
    },
)

export const appVehiclesSlice = createSlice({
    name: "appVehicles",
    initialState: {
        data: [],
        total: 1,
        params: {},
        selectedVehicle: null,
    },
    reducers: {
        setSelected: (state, {payload}) => {
            state.selectedVehicle = payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.total = action.payload.totalRows
                state.params = action.payload.params
            })
            .addCase(getVehicle.fulfilled, (state, {payload}) => {
                state.selectedVehicle = payload
            })
    },
})

export default appVehiclesSlice.reducer
