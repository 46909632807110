/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require("google-protobuf")
var goog = jspb
var global = function () {
    return this || window || global || self || Function("return this")()
}.call(null)

var google_protobuf_timestamp_pb = require("google-protobuf/google/protobuf/timestamp_pb.js")
goog.object.extend(proto, google_protobuf_timestamp_pb)
var projects_erp_service_api_v1_model_money_pb = require("../money_pb.js")
goog.object.extend(proto, projects_erp_service_api_v1_model_money_pb)
goog.exportSymbol(
    "proto.mycargo.projects.erp.api.v1.model.Customer",
    null,
    global,
)
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.model.Customer = function (opt_data) {
    jspb.Message.initialize(
        this,
        opt_data,
        0,
        -1,
        proto.mycargo.projects.erp.api.v1.model.Customer.repeatedFields_,
        null,
    )
}
goog.inherits(proto.mycargo.projects.erp.api.v1.model.Customer, jspb.Message)
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.mycargo.projects.erp.api.v1.model.Customer.displayName =
        "proto.mycargo.projects.erp.api.v1.model.Customer"
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mycargo.projects.erp.api.v1.model.Customer.repeatedFields_ = [20]

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.mycargo.projects.erp.api.v1.model.Customer.prototype.toObject =
        function (opt_includeInstance) {
            return proto.mycargo.projects.erp.api.v1.model.Customer.toObject(
                opt_includeInstance,
                this,
            )
        }

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.mycargo.projects.erp.api.v1.model.Customer} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.mycargo.projects.erp.api.v1.model.Customer.toObject = function (
        includeInstance,
        msg,
    ) {
        var f,
            obj = {
                name: jspb.Message.getFieldWithDefault(msg, 1, ""),
                id: jspb.Message.getFieldWithDefault(msg, 2, ""),
                displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
                firstName: jspb.Message.getFieldWithDefault(msg, 4, ""),
                middleName: jspb.Message.getFieldWithDefault(msg, 5, ""),
                lastName: jspb.Message.getFieldWithDefault(msg, 6, ""),
                countryName: jspb.Message.getFieldWithDefault(msg, 7, ""),
                companyDisplayName: jspb.Message.getFieldWithDefault(
                    msg,
                    8,
                    "",
                ),
                type: jspb.Message.getFieldWithDefault(msg, 9, ""),
                email: jspb.Message.getFieldWithDefault(msg, 10, ""),
                phone: jspb.Message.getFieldWithDefault(msg, 11, ""),
                status: jspb.Message.getFieldWithDefault(msg, 12, ""),
                orderCount: jspb.Message.getFieldWithDefault(msg, 13, 0),
                activeOrderCount: jspb.Message.getFieldWithDefault(msg, 14, 0),
                balance:
                    (f = msg.getBalance()) &&
                    projects_erp_service_api_v1_model_money_pb.Money.toObject(
                        includeInstance,
                        f,
                    ),
                paymentStatus: jspb.Message.getFieldWithDefault(msg, 16, ""),
                paymentDueDate:
                    (f = msg.getPaymentDueDate()) &&
                    google_protobuf_timestamp_pb.Timestamp.toObject(
                        includeInstance,
                        f,
                    ),
                externalId: jspb.Message.getFieldWithDefault(msg, 18, ""),
                pendingBalance:
                    (f = msg.getPendingBalance()) &&
                    projects_erp_service_api_v1_model_money_pb.Money.toObject(
                        includeInstance,
                        f,
                    ),
                buyersList:
                    (f = jspb.Message.getRepeatedField(msg, 20)) == null
                        ? undefined
                        : f,
                activeOrdersBalanceUsd:
                    (f = msg.getactiveOrdersBalanceUsd()) &&
                    projects_erp_service_api_v1_model_money_pb.Money.toObject(
                        includeInstance,
                        f,
                    ),
                activeOrdersPaidUsd:
                    (f = msg.getactiveOrdersPaidUsd()) &&
                    projects_erp_service_api_v1_model_money_pb.Money.toObject(
                        includeInstance,
                        f,
                    ),
                activeOrdersPendingUsd:
                    (f = msg.getactiveOrdersPendingUsd()) &&
                    projects_erp_service_api_v1_model_money_pb.Money.toObject(
                        includeInstance,
                        f,
                    ),
                completedOrdersBalanceUsd:
                    (f = msg.getcompletedOrdersBalanceUsd()) &&
                    projects_erp_service_api_v1_model_money_pb.Money.toObject(
                        includeInstance,
                        f,
                    ),
                completedOrdersPaidUsd:
                    (f = msg.getcompletedOrdersPaidUsd()) &&
                    projects_erp_service_api_v1_model_money_pb.Money.toObject(
                        includeInstance,
                        f,
                    ),
                completedOrdersPendingUsd:
                    (f = msg.getcompletedOrdersPendingUsd()) &&
                    projects_erp_service_api_v1_model_money_pb.Money.toObject(
                        includeInstance,
                        f,
                    ),
                debtedBalanceUsd:
                    (f = msg.getdebtedBalanceUsd()) &&
                    projects_erp_service_api_v1_model_money_pb.Money.toObject(
                        includeInstance,
                        f,
                    ),
                activeOrdersDebtedUsd:
                    (f = msg.getactiveOrdersDebtedUsd()) &&
                    projects_erp_service_api_v1_model_money_pb.Money.toObject(
                        includeInstance,
                        f,
                    ),
                completedOrdersDebtedUsd:
                    (f = msg.getcompletedOrdersDebtedUsd()) &&
                    projects_erp_service_api_v1_model_money_pb.Money.toObject(
                        includeInstance,
                        f,
                    ),
                approvedRefound:
                    (f = msg.getapprovedRefound()) &&
                    projects_erp_service_api_v1_model_money_pb.Money.toObject(
                        includeInstance,
                        f,
                    ),
                allRefoundedAmounts:
                    (f = msg.getallRefoundedAmounts()) &&
                    projects_erp_service_api_v1_model_money_pb.Money.toObject(
                        includeInstance,
                        f,
                    ),
                completedRefoundedAmounts:
                    (f = msg.getCompletedRefoundedAmounts()) &&
                    projects_erp_service_api_v1_model_money_pb.Money.toObject(
                        includeInstance,
                        f,
                    ),
                activeRefoundedAmounts:
                    (f = msg.getActiveRefoundedAmounts()) &&
                    projects_erp_service_api_v1_model_money_pb.Money.toObject(
                        includeInstance,
                        f,
                    ),
            }

        if (includeInstance) {
            obj.$jspbMessageInstance = msg
        }
        return obj
    }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.deserializeBinary = function (
    bytes,
) {
    var reader = new jspb.BinaryReader(bytes)
    var msg = new proto.mycargo.projects.erp.api.v1.model.Customer()
    return proto.mycargo.projects.erp.api.v1.model.Customer.deserializeBinaryFromReader(
        msg,
        reader,
    )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.model.Customer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.deserializeBinaryFromReader =
    function (msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break
            }
            var field = reader.getFieldNumber()
            switch (field) {
                case 1:
                    var value = /** @type {string} */ (reader.readString())
                    msg.setName(value)
                    break
                case 2:
                    var value = /** @type {string} */ (reader.readString())
                    msg.setId(value)
                    break
                case 3:
                    var value = /** @type {string} */ (reader.readString())
                    msg.setDisplayName(value)
                    break
                case 4:
                    var value = /** @type {string} */ (reader.readString())
                    msg.setFirstName(value)
                    break
                case 5:
                    var value = /** @type {string} */ (reader.readString())
                    msg.setMiddleName(value)
                    break
                case 6:
                    var value = /** @type {string} */ (reader.readString())
                    msg.setLastName(value)
                    break
                case 7:
                    var value = /** @type {string} */ (reader.readString())
                    msg.setCountryName(value)
                    break
                case 8:
                    var value = /** @type {string} */ (reader.readString())
                    msg.setCompanyDisplayName(value)
                    break
                case 9:
                    var value = /** @type {string} */ (reader.readString())
                    msg.setType(value)
                    break
                case 10:
                    var value = /** @type {string} */ (reader.readString())
                    msg.setEmail(value)
                    break
                case 11:
                    var value = /** @type {string} */ (reader.readString())
                    msg.setPhone(value)
                    break
                case 12:
                    var value = /** @type {string} */ (reader.readString())
                    msg.setStatus(value)
                    break
                case 13:
                    var value = /** @type {number} */ (reader.readUint32())
                    msg.setOrderCount(value)
                    break
                case 14:
                    var value = /** @type {number} */ (reader.readUint32())
                    msg.setActiveOrderCount(value)
                    break
                case 15:
                    var value =
                        new projects_erp_service_api_v1_model_money_pb.Money()
                    reader.readMessage(
                        value,
                        projects_erp_service_api_v1_model_money_pb.Money
                            .deserializeBinaryFromReader,
                    )
                    msg.setBalance(value)
                    break
                case 16:
                    var value = /** @type {string} */ (reader.readString())
                    msg.setPaymentStatus(value)
                    break
                case 17:
                    var value = new google_protobuf_timestamp_pb.Timestamp()
                    reader.readMessage(
                        value,
                        google_protobuf_timestamp_pb.Timestamp
                            .deserializeBinaryFromReader,
                    )
                    msg.setPaymentDueDate(value)
                    break
                case 18:
                    var value = /** @type {string} */ (reader.readString())
                    msg.setExternalId(value)
                    break
                case 19:
                    var value =
                        new projects_erp_service_api_v1_model_money_pb.Money()
                    reader.readMessage(
                        value,
                        projects_erp_service_api_v1_model_money_pb.Money
                            .deserializeBinaryFromReader,
                    )
                    msg.setPendingBalance(value)
                    break
                case 20:
                    var value = /** @type {string} */ (reader.readString())
                    msg.addBuyers(value)
                    break
                default:
                    reader.skipField()
                    break
                case 21:
                    var value =
                        new projects_erp_service_api_v1_model_money_pb.Money()
                    reader.readMessage(
                        value,
                        projects_erp_service_api_v1_model_money_pb.Money
                            .deserializeBinaryFromReader,
                    )
                    msg.setactiveOrdersBalanceUsd(value)
                    break
                case 22:
                    var value =
                        new projects_erp_service_api_v1_model_money_pb.Money()
                    reader.readMessage(
                        value,
                        projects_erp_service_api_v1_model_money_pb.Money
                            .deserializeBinaryFromReader,
                    )
                    msg.setactiveOrdersPaidUsd(value)
                    break
                case 23:
                    var value =
                        new projects_erp_service_api_v1_model_money_pb.Money()
                    reader.readMessage(
                        value,
                        projects_erp_service_api_v1_model_money_pb.Money
                            .deserializeBinaryFromReader,
                    )
                    msg.setactiveOrdersPendingUsd(value)
                    break
                case 24:
                    var value =
                        new projects_erp_service_api_v1_model_money_pb.Money()
                    reader.readMessage(
                        value,
                        projects_erp_service_api_v1_model_money_pb.Money
                            .deserializeBinaryFromReader,
                    )
                    msg.setcompletedOrdersBalanceUsd(value)
                    break
                case 25:
                    var value =
                        new projects_erp_service_api_v1_model_money_pb.Money()
                    reader.readMessage(
                        value,
                        projects_erp_service_api_v1_model_money_pb.Money
                            .deserializeBinaryFromReader,
                    )
                    msg.setcompletedOrdersPaidUsd(value)
                    break
                case 26:
                    var value =
                        new projects_erp_service_api_v1_model_money_pb.Money()
                    reader.readMessage(
                        value,
                        projects_erp_service_api_v1_model_money_pb.Money
                            .deserializeBinaryFromReader,
                    )
                    msg.setcompletedOrdersPendingUsd(value)
                    break
                case 27:
                    var value =
                        new projects_erp_service_api_v1_model_money_pb.Money()
                    reader.readMessage(
                        value,
                        projects_erp_service_api_v1_model_money_pb.Money
                            .deserializeBinaryFromReader,
                    )
                    msg.setdebtedBalanceUsd(value)
                    break
                case 28:
                    var value =
                        new projects_erp_service_api_v1_model_money_pb.Money()
                    reader.readMessage(
                        value,
                        projects_erp_service_api_v1_model_money_pb.Money
                            .deserializeBinaryFromReader,
                    )
                    msg.setactiveOrdersDebtedUsd(value)
                    break
                case 29:
                    var value =
                        new projects_erp_service_api_v1_model_money_pb.Money()
                    reader.readMessage(
                        value,
                        projects_erp_service_api_v1_model_money_pb.Money
                            .deserializeBinaryFromReader,
                    )
                    msg.setcompletedOrdersDebtedUsd(value)
                    break
                case 30:
                    var value =
                        new projects_erp_service_api_v1_model_money_pb.Money()
                    reader.readMessage(
                        value,
                        projects_erp_service_api_v1_model_money_pb.Money
                            .deserializeBinaryFromReader,
                    )
                    msg.setapprovedRefound(value)
                    break
                case 31:
                    var value =
                        new projects_erp_service_api_v1_model_money_pb.Money()
                    reader.readMessage(
                        value,
                        projects_erp_service_api_v1_model_money_pb.Money
                            .deserializeBinaryFromReader,
                    )
                    msg.setallRefoundedAmounts(value)
                    break
                case 32:
                    var value =
                        new projects_erp_service_api_v1_model_money_pb.Money()
                    reader.readMessage(
                        value,
                        projects_erp_service_api_v1_model_money_pb.Money
                            .deserializeBinaryFromReader,
                    )
                    msg.setCompletedRefoundedAmounts(value)
                    break
                case 33:
                    var value =
                        new projects_erp_service_api_v1_model_money_pb.Money()
                    reader.readMessage(
                        value,
                        projects_erp_service_api_v1_model_money_pb.Money
                            .deserializeBinaryFromReader,
                    )
                    msg.setActiveRefoundedAmounts(value)
                    break
            }
        }
        return msg
    }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.serializeBinary =
    function () {
        var writer = new jspb.BinaryWriter()
        proto.mycargo.projects.erp.api.v1.model.Customer.serializeBinaryToWriter(
            this,
            writer,
        )
        return writer.getResultBuffer()
    }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.model.Customer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.model.Customer.serializeBinaryToWriter =
    function (message, writer) {
        var f = undefined
        f = message.getName()
        if (f.length > 0) {
            writer.writeString(1, f)
        }
        f = message.getId()
        if (f.length > 0) {
            writer.writeString(2, f)
        }
        f = message.getDisplayName()
        if (f.length > 0) {
            writer.writeString(3, f)
        }
        f = message.getFirstName()
        if (f.length > 0) {
            writer.writeString(4, f)
        }
        f = message.getMiddleName()
        if (f.length > 0) {
            writer.writeString(5, f)
        }
        f = message.getLastName()
        if (f.length > 0) {
            writer.writeString(6, f)
        }
        f = message.getCountryName()
        if (f.length > 0) {
            writer.writeString(7, f)
        }
        f = message.getCompanyDisplayName()
        if (f.length > 0) {
            writer.writeString(8, f)
        }
        f = message.getType()
        if (f.length > 0) {
            writer.writeString(9, f)
        }
        f = message.getEmail()
        if (f.length > 0) {
            writer.writeString(10, f)
        }
        f = message.getPhone()
        if (f.length > 0) {
            writer.writeString(11, f)
        }
        f = message.getStatus()
        if (f.length > 0) {
            writer.writeString(12, f)
        }
        f = message.getOrderCount()
        if (f !== 0) {
            writer.writeUint32(13, f)
        }
        f = message.getActiveOrderCount()
        if (f !== 0) {
            writer.writeUint32(14, f)
        }
        f = message.getBalance()
        if (f != null) {
            writer.writeMessage(
                15,
                f,
                projects_erp_service_api_v1_model_money_pb.Money
                    .serializeBinaryToWriter,
            )
        }
        f = message.getPaymentStatus()
        if (f.length > 0) {
            writer.writeString(16, f)
        }
        f = message.getPaymentDueDate()
        if (f != null) {
            writer.writeMessage(
                17,
                f,
                google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter,
            )
        }
        f = message.getExternalId()
        if (f.length > 0) {
            writer.writeString(18, f)
        }
        f = message.getPendingBalance()
        if (f != null) {
            writer.writeMessage(
                19,
                f,
                projects_erp_service_api_v1_model_money_pb.Money
                    .serializeBinaryToWriter,
            )
        }
        f = message.getBuyersList()
        if (f.length > 0) {
            writer.writeRepeatedString(20, f)
        }
        f = message.getactiveOrdersBalanceUsd()
        if (f != null) {
            writer.writeMessage(
                21,
                f,
                projects_erp_service_api_v1_model_money_pb.Money
                    .serializeBinaryToWriter,
            )
        }
        f = message.getactiveOrdersPaidUsd()
        if (f != null) {
            writer.writeMessage(
                22,
                f,
                projects_erp_service_api_v1_model_money_pb.Money
                    .serializeBinaryToWriter,
            )
        }
        f = message.getactiveOrdersPendingUsd()
        if (f != null) {
            writer.writeMessage(
                23,
                f,
                projects_erp_service_api_v1_model_money_pb.Money
                    .serializeBinaryToWriter,
            )
        }
        f = message.getcompletedOrdersBalanceUsd()
        if (f != null) {
            writer.writeMessage(
                24,
                f,
                projects_erp_service_api_v1_model_money_pb.Money
                    .serializeBinaryToWriter,
            )
        }
        f = message.getcompletedOrdersPaidUsd()
        if (f != null) {
            writer.writeMessage(
                25,
                f,
                projects_erp_service_api_v1_model_money_pb.Money
                    .serializeBinaryToWriter,
            )
        }
        f = message.getcompletedOrdersPendingUsd()
        if (f != null) {
            writer.writeMessage(
                26,
                f,
                projects_erp_service_api_v1_model_money_pb.Money
                    .serializeBinaryToWriter,
            )
        }
        f = message.getdebtedBalanceUsd()
        if (f != null) {
            writer.writeMessage(
                27,
                f,
                projects_erp_service_api_v1_model_money_pb.Money
                    .serializeBinaryToWriter,
            )
        }
        f = message.getactiveOrdersDebtedUsd()
        if (f != null) {
            writer.writeMessage(
                28,
                f,
                projects_erp_service_api_v1_model_money_pb.Money
                    .serializeBinaryToWriter,
            )
        }
        f = message.getcompletedOrdersDebtedUsd()
        if (f != null) {
            writer.writeMessage(
                29,
                f,
                projects_erp_service_api_v1_model_money_pb.Money
                    .serializeBinaryToWriter,
            )
        }
        f = message.getapprovedRefound()
        if (f != null) {
            writer.writeMessage(
                30,
                f,
                projects_erp_service_api_v1_model_money_pb.Money
                    .serializeBinaryToWriter,
            )
        }
        f = message.getallRefoundedAmounts()
        if (f != null) {
            writer.writeMessage(
                31,
                f,
                projects_erp_service_api_v1_model_money_pb.Money
                    .serializeBinaryToWriter,
            )
        }
        f = message.getCompletedRefoundedAmounts()
        if (f != null) {
            writer.writeMessage(
                32,
                f,
                projects_erp_service_api_v1_model_money_pb.Money
                    .serializeBinaryToWriter,
            )
        }
        f = message.getActiveRefoundedAmounts()
        if (f != null) {
            writer.writeMessage(
                33,
                f,
                projects_erp_service_api_v1_model_money_pb.Money
                    .serializeBinaryToWriter,
            )
        }
    }

/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getName =
    function () {
        return /** @type {string} */ (
            jspb.Message.getFieldWithDefault(this, 1, "")
        )
    }

/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setName = function (
    value,
) {
    return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string id = 2;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""))
}

/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setId = function (
    value,
) {
    return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getDisplayName =
    function () {
        return /** @type {string} */ (
            jspb.Message.getFieldWithDefault(this, 3, "")
        )
    }

/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setDisplayName =
    function (value) {
        return jspb.Message.setProto3StringField(this, 3, value)
    }

/**
 * optional string first_name = 4;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getFirstName =
    function () {
        return /** @type {string} */ (
            jspb.Message.getFieldWithDefault(this, 4, "")
        )
    }

/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setFirstName =
    function (value) {
        return jspb.Message.setProto3StringField(this, 4, value)
    }

/**
 * optional string middle_name = 5;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getMiddleName =
    function () {
        return /** @type {string} */ (
            jspb.Message.getFieldWithDefault(this, 5, "")
        )
    }

/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setMiddleName =
    function (value) {
        return jspb.Message.setProto3StringField(this, 5, value)
    }

/**
 * optional string last_name = 6;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getLastName =
    function () {
        return /** @type {string} */ (
            jspb.Message.getFieldWithDefault(this, 6, "")
        )
    }

/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setLastName =
    function (value) {
        return jspb.Message.setProto3StringField(this, 6, value)
    }

/**
 * optional string country_name = 7;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getCountryName =
    function () {
        return /** @type {string} */ (
            jspb.Message.getFieldWithDefault(this, 7, "")
        )
    }

/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setCountryName =
    function (value) {
        return jspb.Message.setProto3StringField(this, 7, value)
    }

/**
 * optional string company_display_name = 8;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getCompanyDisplayName =
    function () {
        return /** @type {string} */ (
            jspb.Message.getFieldWithDefault(this, 8, "")
        )
    }

/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setCompanyDisplayName =
    function (value) {
        return jspb.Message.setProto3StringField(this, 8, value)
    }

/**
 * optional string type = 9;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getType =
    function () {
        return /** @type {string} */ (
            jspb.Message.getFieldWithDefault(this, 9, "")
        )
    }

/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setType = function (
    value,
) {
    return jspb.Message.setProto3StringField(this, 9, value)
}

/**
 * optional string email = 10;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getEmail =
    function () {
        return /** @type {string} */ (
            jspb.Message.getFieldWithDefault(this, 10, "")
        )
    }

/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setEmail = function (
    value,
) {
    return jspb.Message.setProto3StringField(this, 10, value)
}

/**
 * optional string phone = 11;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getPhone =
    function () {
        return /** @type {string} */ (
            jspb.Message.getFieldWithDefault(this, 11, "")
        )
    }

/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setPhone = function (
    value,
) {
    return jspb.Message.setProto3StringField(this, 11, value)
}

/**
 * optional string status = 12;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getStatus =
    function () {
        return /** @type {string} */ (
            jspb.Message.getFieldWithDefault(this, 12, "")
        )
    }

/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setStatus =
    function (value) {
        return jspb.Message.setProto3StringField(this, 12, value)
    }

/**
 * optional uint32 order_count = 13;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getOrderCount =
    function () {
        return /** @type {number} */ (
            jspb.Message.getFieldWithDefault(this, 13, 0)
        )
    }

/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setOrderCount =
    function (value) {
        return jspb.Message.setProto3IntField(this, 13, value)
    }

/**
 * optional uint32 active_order_count = 14;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getActiveOrderCount =
    function () {
        return /** @type {number} */ (
            jspb.Message.getFieldWithDefault(this, 14, 0)
        )
    }

/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setActiveOrderCount =
    function (value) {
        return jspb.Message.setProto3IntField(this, 14, value)
    }

/**
 * optional Money balance = 15;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getBalance =
    function () {
        return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
            jspb.Message.getWrapperField(
                this,
                projects_erp_service_api_v1_model_money_pb.Money,
                15,
            )
        )
    }

/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setBalance =
    function (value) {
        return jspb.Message.setWrapperField(this, 15, value)
    }

/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.clearBalance =
    function () {
        return this.setBalance(undefined)
    }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hasBalance =
    function () {
        return jspb.Message.getField(this, 15) != null
    }

/**
 * optional string payment_status = 16;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getPaymentStatus =
    function () {
        return /** @type {string} */ (
            jspb.Message.getFieldWithDefault(this, 16, "")
        )
    }

/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setPaymentStatus =
    function (value) {
        return jspb.Message.setProto3StringField(this, 16, value)
    }

/**
 * optional google.protobuf.Timestamp payment_due_date = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getPaymentDueDate =
    function () {
        return /** @type{?proto.google.protobuf.Timestamp} */ (
            jspb.Message.getWrapperField(
                this,
                google_protobuf_timestamp_pb.Timestamp,
                17,
            )
        )
    }

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setPaymentDueDate =
    function (value) {
        return jspb.Message.setWrapperField(this, 17, value)
    }

/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.clearPaymentDueDate =
    function () {
        return this.setPaymentDueDate(undefined)
    }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hasPaymentDueDate =
    function () {
        return jspb.Message.getField(this, 17) != null
    }

/**
 * optional string external_id = 18;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getExternalId =
    function () {
        return /** @type {string} */ (
            jspb.Message.getFieldWithDefault(this, 18, "")
        )
    }

/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setExternalId =
    function (value) {
        return jspb.Message.setProto3StringField(this, 18, value)
    }

/**
 * optional Money pending_balance = 19;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getPendingBalance =
    function () {
        return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
            jspb.Message.getWrapperField(
                this,
                projects_erp_service_api_v1_model_money_pb.Money,
                19,
            )
        )
    }

/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setPendingBalance =
    function (value) {
        return jspb.Message.setWrapperField(this, 19, value)
    }

/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.clearPendingBalance =
    function () {
        return this.setPendingBalance(undefined)
    }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hasPendingBalance =
    function () {
        return jspb.Message.getField(this, 19) != null
    }

/**
 * repeated string buyers = 20;
 * @return {!Array<string>}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getBuyersList =
    function () {
        return /** @type {!Array<string>} */ (
            jspb.Message.getRepeatedField(this, 20)
        )
    }

/**
 * @param {!Array<string>} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setBuyersList =
    function (value) {
        return jspb.Message.setField(this, 20, value || [])
    }

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.addBuyers =
    function (value, opt_index) {
        return jspb.Message.addToRepeatedField(this, 20, value, opt_index)
    }

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.clearBuyersList =
    function () {
        return this.setBuyersList([])
    }

/**
 * optional Money active_orders_balance = 21;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getactiveOrdersBalanceUsd =
    function () {
        return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
            jspb.Message.getWrapperField(
                this,
                projects_erp_service_api_v1_model_money_pb.Money,
                21,
            )
        )
    }

/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setactiveOrdersBalanceUsd =
    function (value) {
        return jspb.Message.setWrapperField(this, 21, value)
    }

/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.clearactiveOrdersBalanceUsd =
    function () {
        return this.setactiveOrdersBalanceUsd(undefined)
    }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hasactiveOrdersBalanceUsd =
    function () {
        return jspb.Message.getField(this, 21) != null
    }

/**
 * optional Money active_orders_balance = 21;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getactiveOrdersPaidUsd =
    function () {
        return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
            jspb.Message.getWrapperField(
                this,
                projects_erp_service_api_v1_model_money_pb.Money,
                22,
            )
        )
    }

/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setactiveOrdersPaidUsd =
    function (value) {
        return jspb.Message.setWrapperField(this, 22, value)
    }

/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.clearactiveOrdersPaidUsd =
    function () {
        return this.setactiveOrdersPaidUsd(undefined)
    }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hasactiveOrdersPaidUsd =
    function () {
        return jspb.Message.getField(this, 22) != null
    }

/**
 * optional Money active_orders_balance = 23;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getactiveOrdersPendingUsd =
    function () {
        return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
            jspb.Message.getWrapperField(
                this,
                projects_erp_service_api_v1_model_money_pb.Money,
                23,
            )
        )
    }

/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setactiveOrdersPendingUsd =
    function (value) {
        return jspb.Message.setWrapperField(this, 23, value)
    }

/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.clearactiveOrdersPendingUsd =
    function () {
        return this.setactiveOrdersPendingUsd(undefined)
    }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hasactiveOrdersPendingUsd =
    function () {
        return jspb.Message.getField(this, 23) != null
    }

/**
 * optional Money active_orders_balance = 24;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getcompletedOrdersBalanceUsd =
    function () {
        return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
            jspb.Message.getWrapperField(
                this,
                projects_erp_service_api_v1_model_money_pb.Money,
                24,
            )
        )
    }

/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setcompletedOrdersBalanceUsd =
    function (value) {
        return jspb.Message.setWrapperField(this, 24, value)
    }

/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.clearcompletedOrdersBalanceUsd =
    function () {
        return this.setcompletedOrdersBalanceUsd(undefined)
    }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hascompletedOrdersBalanceUsd =
    function () {
        return jspb.Message.getField(this, 24) != null
    }

/**
 * optional Money active_orders_balance = 25;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getcompletedOrdersPaidUsd =
    function () {
        return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
            jspb.Message.getWrapperField(
                this,
                projects_erp_service_api_v1_model_money_pb.Money,
                25,
            )
        )
    }

/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setcompletedOrdersPaidUsd =
    function (value) {
        return jspb.Message.setWrapperField(this, 25, value)
    }

/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.clearcompletedOrdersPaidUsd =
    function () {
        return this.setcompletedOrdersPaidUsd(undefined)
    }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hascompletedOrdersPaidUsd =
    function () {
        return jspb.Message.getField(this, 25) != null
    }

/**
 * optional Money active_orders_balance = 26;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getcompletedOrdersPendingUsd =
    function () {
        return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
            jspb.Message.getWrapperField(
                this,
                projects_erp_service_api_v1_model_money_pb.Money,
                26,
            )
        )
    }

/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setcompletedOrdersPendingUsd =
    function (value) {
        return jspb.Message.setWrapperField(this, 26, value)
    }

/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.clearcompletedOrdersPendingUsd =
    function () {
        return this.setcompletedOrdersPendingUsd(undefined)
    }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hasdebtedBalanceUsd =
    function () {
        return jspb.Message.getField(this, 27) != null
    }
/**
 * optional Money active_orders_balance = 26;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getdebtedBalanceUsd =
    function () {
        return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
            jspb.Message.getWrapperField(
                this,
                projects_erp_service_api_v1_model_money_pb.Money,
                27,
            )
        )
    }

/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setdebtedBalanceUsd =
    function (value) {
        return jspb.Message.setWrapperField(this, 27, value)
    }

/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.cleardebtedBalanceUsd =
    function () {
        return this.setdebtedBalanceUsd(undefined)
    }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hasdebtedBalanceUsd =
    function () {
        return jspb.Message.getField(this, 27) != null
    }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hasactiveOrdersDebtedUsd =
    function () {
        return jspb.Message.getField(this, 28) != null
    }
/**
 * optional Money active_orders_balance = 26;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getactiveOrdersDebtedUsd =
    function () {
        return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
            jspb.Message.getWrapperField(
                this,
                projects_erp_service_api_v1_model_money_pb.Money,
                28,
            )
        )
    }

/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setactiveOrdersDebtedUsd =
    function (value) {
        return jspb.Message.setWrapperField(this, 28, value)
    }

/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.clearactiveOrdersDebtedUsd =
    function () {
        return this.setactiveOrdersDebtedUsd(undefined)
    }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hasactiveOrdersDebtedUsd =
    function () {
        return jspb.Message.getField(this, 28) != null
    }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hascompletedOrdersDebtedUsd =
    function () {
        return jspb.Message.getField(this, 29) != null
    }
/**
 * optional Money completed_orders_debted = 29;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getcompletedOrdersDebtedUsd =
    function () {
        return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
            jspb.Message.getWrapperField(
                this,
                projects_erp_service_api_v1_model_money_pb.Money,
                29,
            )
        )
    }

/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setcompletedOrdersDebtedUsd =
    function (value) {
        return jspb.Message.setWrapperField(this, 29, value)
    }

/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.clearcompletedOrdersDebtedUsd =
    function () {
        return this.setcompletedOrdersDebtedUsd(undefined)
    }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hascompletedOrdersDebtedUsd =
    function () {
        return jspb.Message.getField(this, 29) != null
    }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hasapprovedRefound =
    function () {
        return jspb.Message.getField(this, 30) != null
    }
/**
 * optional Money approved_refound = 30;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getapprovedRefound =
    function () {
        return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
            jspb.Message.getWrapperField(
                this,
                projects_erp_service_api_v1_model_money_pb.Money,
                30,
            )
        )
    }

/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setapprovedRefound =
    function (value) {
        return jspb.Message.setWrapperField(this, 30, value)
    }

/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.clearapprovedRefound =
    function () {
        return this.setapprovedRefound(undefined)
    }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hasapprovedRefound =
    function () {
        return jspb.Message.getField(this, 30) != null
    }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hasallRefoundedAmounts =
    function () {
        return jspb.Message.getField(this, 31) != null
    }
/**
 * optional Money refounded_amount = 31;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getallRefoundedAmounts =
    function () {
        return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
            jspb.Message.getWrapperField(
                this,
                projects_erp_service_api_v1_model_money_pb.Money,
                31,
            )
        )
    }

/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setallRefoundedAmounts =
    function (value) {
        return jspb.Message.setWrapperField(this, 31, value)
    }

/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.clearallRefoundedAmounts =
    function () {
        return this.setallRefoundedAmounts(undefined)
    }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hasallRefoundedAmounts =
    function () {
        return jspb.Message.getField(this, 31) != null
    }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hasCompletedRefoundedAmounts =
    function () {
        return jspb.Message.getField(this, 32) != null
    }
/**
 * optional Money refounded_amount = 32;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getCompletedRefoundedAmounts =
    function () {
        return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
            jspb.Message.getWrapperField(
                this,
                projects_erp_service_api_v1_model_money_pb.Money,
                32,
            )
        )
    }

/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setCompletedRefoundedAmounts =
    function (value) {
        return jspb.Message.setWrapperField(this, 32, value)
    }

/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.clearCompletedRefoundedAmounts =
    function () {
        return this.setallRefoundedAmounts(undefined)
    }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hasCompletedRefoundedAmounts =
    function () {
        return jspb.Message.getField(this, 32) != null
    }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hasActiveRefoundedAmounts =
    function () {
        return jspb.Message.getField(this, 33) != null
    }
/**
 * optional Money refounded_amount = 33;
 * @return {?proto.mycargo.projects.erp.api.v1.model.Money}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.getActiveRefoundedAmounts =
    function () {
        return /** @type{?proto.mycargo.projects.erp.api.v1.model.Money} */ (
            jspb.Message.getWrapperField(
                this,
                projects_erp_service_api_v1_model_money_pb.Money,
                33,
            )
        )
    }

/**
 * @param {?proto.mycargo.projects.erp.api.v1.model.Money|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.setActiveRefoundedAmounts =
    function (value) {
        return jspb.Message.setWrapperField(this, 33, value)
    }

/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.model.Customer} returns this
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.clearActiveRefoundedAmounts =
    function () {
        return this.setallRefoundedAmounts(undefined)
    }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.model.Customer.prototype.hasActiveRefoundedAmounts =
    function () {
        return jspb.Message.getField(this, 33) != null
    }

goog.object.extend(exports, proto.mycargo.projects.erp.api.v1.model)
